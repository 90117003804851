import React from "react";
import { InputLabel, MenuItem, Box, FormControl } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import MuiSelect from "@mui/material/Select";

export const MaterialMenu = (props) => {
  const { setTransparentMaterial, setPremiumMaterial, material } = props;
  const { t } = useTranslation();

  const Select = withStyles((theme) => ({
    select: {
      whiteSpace: "break-spaces !important",
      textOverflow: "clip !important",
    },
  }))(MuiSelect);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t("material")}</InputLabel>
        <Select
          sx={{ fontSize: "12px", height: "60px" }}
          value={material}
          label={t("material")}
          onChange={(event) => {
            if (event.target.value === "semitransparent") {
              setTransparentMaterial("semitransparent");
            } else if (event.target.value === "transparent") {
              setTransparentMaterial("transparent");
            } else {
              setPremiumMaterial(event.target.value);
            }
          }}
        >
          <MenuItem value="matte_paper" style={{ minWidth: "100%" }}>
            {t("matte.paper")}
          </MenuItem>
          <MenuItem
            value="glossy_paper"
            style={{ minWidth: "100%", backgroundColor: "white" }}
          >
            {t("glossy.paper")}
          </MenuItem>
          <MenuItem value="superglossy_paper" style={{ minWidth: "100%" }}>
            {t("superglossy.paper")}
          </MenuItem>
          <MenuItem value="matte_pvc" style={{ minWidth: "100%" }}>
            {t("matte.pvc")}
          </MenuItem>
          <MenuItem value="glossy_pvc" style={{ minWidth: "100%" }}>
            {t("glossy.pvc")}
          </MenuItem>
          <MenuItem value="semitransparent" style={{ minWidth: "100%" }}>
            {t("semitransparent.pvc")}
          </MenuItem>
          <MenuItem value="transparent" style={{ minWidth: "100%" }}>
            {t("transparent")}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
