import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  Slider,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
  useMediaQuery,
  Fade,
  ClickAwayListener,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChromePicker } from "react-color";
import SquareIcon from "@mui/icons-material/Square";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoIcon from "@mui/icons-material/Info";
import { Helmet } from "react-helmet";

import Background_transparent from "../../assets/transparent_background.webp";
import {
  alignObjectHorizontal,
  alignObjectVertical,
  changeColor,
  drawCircle,
  drawRectangle,
  drawRoundedRectangle,
  flipObjectHorizontal,
  flipObjectVertical,
  handleCanvasDownload,
  removeFolioGraphics,
  resetCanvases,
  resizeCanvasShape,
  setPremiumBackground,
  setTransparentBackground,
  uploadFolioGraphics,
  uploadImageToShape,
} from "./utils/shapeProcess";
import { colors } from "../..";
import { UploadModal } from "./utils/UploadModal";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNoOnSheetValue,
  changeStickersValue,
  selectNoOnSheet,
} from "./createStickerSlice";
import AdbIcon from "@mui/icons-material/Adb";
import { ImageActionButtons } from "./utils/ImageActionsButtons";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useTranslation } from "react-i18next";
import { displayToastSuccess } from "../../utils/ErrorBoudary/errorBoudarySlice";
import { MaterialMenu } from "./utils/MaterialMenu";
import { WrappingMenu } from "./utils/WrappingMenu";
import { ShapeMenu } from "./utils/ShapeMenu";
import {
  computeMaxWidthHeight,
  computeNumberOfStickersOnSheetOfPaper,
} from "./utils/shapeProcessHelperFunctions";
import { PricingList } from "../Pricing/Pricing";
import {
  changeCurrentPriceValue,
  changeTotalPriceValue,
  selectCurrentPrice,
} from "../Pricing/pricingSlice";
import { MATERIAL_TYPES_WITH_PVC } from "../Auth/constants";
import Resizer from "react-image-file-resizer";

const useStyles = makeStyles({
  colorPicker: {
    display: "flex",
    flex: "1 !important",
    width: "100% !important",
    height: "20vh !important",
    padding: "12px",
    "&:first-child > :first-child": {
      width: "60% !important",
      paddingBottom: "0 !important",
      borderRadius: "12px !important",
    },
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export const CreateSticker = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showColors, setShowColors] = useState(false);
  const [width, setWidth] = useState(2);
  const [height, setHeight] = useState(2);
  const [maxHeightPrint, setMaxHeightPrint] = useState(0);
  const [maxWidthPrint, setMaxWidthPrint] = useState(0);
  const [widthPrint, setWidthPrint] = useState(0);
  const [heightPrint, setHeightPrint] = useState(0);
  const [quantity, setQuantity] = useState(10);
  const [material, setMaterial] = useState("glossy_paper");
  const [isRectangle, setIsRectangle] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [isRoudedCorners, setIsRoundedCorners] = useState(false);
  const [rectRatio, setRectRatio] = useState(0);
  const [isTransparent, setIsTransparent] = useState(false);
  const [imageBackgroundColor, setimageBackgroundColor] = useState("#ff0000");
  const [sliderValue, setSliderValue] = useState(320);
  const [menuIn, setMenuIn] = useState(false);
  const [hasFolio, setHasFolio] = useState(false);
  const folioInputRef = useRef(null);
  const [shape, setShape] = useState("rectangular");
  // const [isChrome, setIsChrome] = useState(false);
  // const [chromeEffect, setChromeEffect] = useState(false);
  const [isDieCut, setIsDieCut] = useState(false);
  const dispatch = useDispatch();
  const currentPrice = useSelector(selectCurrentPrice);
  const [anchorEl, setAnchorEl] = useState(null);

  const [wrapping, setWrapping] = useState("None");

  const noOnSheet = useSelector(selectNoOnSheet);
  const matches = useMediaQuery("(min-width:900px)");
  const numberOfSheet = Math.ceil(quantity / noOnSheet);

  const handleAddStickerToCard = (sticker) => {
    dispatch(changeStickersValue(sticker));
    dispatch(displayToastSuccess("Add to cart succesfull"));
    dispatch(changeTotalPriceValue(currentPrice));
    dispatch(changeNoOnSheetValue(0));
  };

  useEffect(() => {
    resetStateForNewSticker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const maxValues = computeMaxWidthHeight(rectRatio, material);
    setMaxHeightPrint(maxValues.maxHeight);
    setMaxWidthPrint(maxValues.maxWidth);
    if (parseInt(widthPrint) !== 0 && widthPrint > maxValues.maxWidth) {
      setWidthRatio(maxValues.maxWidth, isRectangle);
    }
    if (heightPrint !== 0 && heightPrint > maxValues.maxHeight) {
      setHeightRatio(maxValues.maxHeight, isRectangle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [material]);

  // const handleSelectGoldColor = (isChrome) => {
  //   drawChromeEffect(isRectangle, isRoudedCorners, isChrome);
  // };

  const resetStateForNewSticker = () => {
    setWidth(2);
    setHeight(2);
    setHeightPrint(0);
    setQuantity(10);
    setWidthPrint(0);
    setMaterial("glossy_paper");
    setIsRoundedCorners(false);
    setIsRectangle(true);
    setRectRatio(0);
    setIsTransparent(false);
    setimageBackgroundColor("#ffffff");
    setSliderValue(320);
    setShowColors(false);
    setIsDieCut(false);
    dispatch(changeCurrentPriceValue(0));
    setShape("rectangular");
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const addImageHanlder = async (event) => {
    let image;
    image = URL.createObjectURL(event.target.files[0]);
    uploadImageToShape(
      image,
      setRectangularRatio,
      setWidth,
      setHeight,
      isRectangle,
      setMaxHeightPrint,
      setMaxWidthPrint,
      material,
      setQuantity
    );
    setOpenModal(false);
  };

  const addFolioGraphicsHandler = (event) => {
    const image = URL.createObjectURL(event.target.files[0]);
    uploadFolioGraphics(image);
    setHasFolio(true);
    setWrapping("SoftTouch");
  };

  const removeFolioGraphicsHandles = () => {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm("Are you sure?");
    if (result) {
      removeFolioGraphics();
      setHasFolio(false);
    }
  };

  const setRectangularRatio = (ratio) => {
    setRectRatio(ratio);
    setWidthPrint(10);
    if (ratio === 1) {
      setHeightPrint(10);
      handleSetNoOfStickersPerPage(isDieCut, 10, 10);
    } else if (ratio > 1) {
      setHeightPrint(((parseFloat(10) * 1) / ratio).toFixed(2));
      handleSetNoOfStickersPerPage(
        isDieCut,
        10,
        ((parseFloat(10) * 1) / ratio).toFixed(2)
      );
    } else {
      setHeightPrint(parseFloat(10 * (1 / ratio)).toFixed(2));
      handleSetNoOfStickersPerPage(
        isDieCut,
        10,
        parseFloat(10 * (1 / ratio)).toFixed(2)
      );
    }
  };

  const resizeShape = (value) => {
    resizeCanvasShape(value, setWidth, setHeight, isRectangle);
  };

  const handleResetCanvas = () => {
    setOpenModal(true);
    resetStateForNewSticker();
    resetCanvases();
  };

  const handleDownload = () => {
    handleCanvasDownload(
      width,
      height,
      (widthPrint * 96) / 2.54,
      (heightPrint * 96) / 2.54,
      quantity,
      material,
      isRectangle,
      isTransparent,
      isRoudedCorners,
      handleAddStickerToCard,
      isDieCut,
      currentPrice,
      noOnSheet,
      wrapping
    );
    setOpenModal(true);
    handleResetCanvas();
  };

  const handleChangeColor = (color) => {
    setimageBackgroundColor(color.hex);
    changeColor(color.hex);
  };

  const handleSetNoOfStickersPerPage = (
    dieCut,
    width,
    height,
    changeQuantity
  ) => {
    const noOnSheetOfPaper = computeNumberOfStickersOnSheetOfPaper(
      dieCut,
      width,
      height,
      material
    );

    changeQuantity && setQuantity(numberOfSheet * noOnSheetOfPaper);
    dispatch(changeNoOnSheetValue(noOnSheetOfPaper));
  };
  const setWidthRatio = (value, isRectangleShape) => {
    setWidthPrint(value);
    let heightValue = 0;
    if (!isRectangleShape) {
      setHeightPrint(parseFloat(value));
      heightValue = parseFloat(value);
      handleSetNoOfStickersPerPage(isDieCut, value, value, true);
      return;
    }
    if (rectRatio === 1) {
      setHeightPrint(parseFloat(value));
      heightValue = parseFloat(value);
      // } else if (rectRatio > 1) {
      //   setHeightPrint((parseFloat(value) * (1 / rectRatio)).toFixed(2));
      //   heightValue = (parseFloat(value) * (1 / rectRatio)).toFixed(2);
    } else {
      setHeightPrint((parseFloat(value) * (1 / rectRatio)).toFixed(2));
      heightValue = (parseFloat(value) * (1 / rectRatio)).toFixed(2);
    }

    handleSetNoOfStickersPerPage(isDieCut, value, heightValue, true);
  };

  const setHeightRatio = (value, isRectangleShape) => {
    setHeightPrint(value);
    let widthValue = 0;
    if (!isRectangleShape) {
      setWidthPrint(parseFloat(value));
      handleSetNoOfStickersPerPage(isDieCut, value, value, true);
      return;
    }
    if (rectRatio === 1) {
      setWidthPrint(parseFloat(value));
      widthValue = value;
      // } else if (rectRatio > 1) {
      //   setWidthPrint((parseFloat(value) * rectRatio).toFixed(2));
      //   widthValue = (parseFloat(value) * rectRatio).toFixed(2);
    } else {
      setWidthPrint((parseFloat(value) * rectRatio).toFixed(2));
      widthValue = (parseFloat(value) * rectRatio).toFixed(2);
    }
    handleSetNoOfStickersPerPage(isDieCut, widthValue, value, true);
  };

  const handleSelectRectangularShape = () => {
    drawRectangle();
    setIsRectangle(true);
    setIsRoundedCorners(false);
    resizeCanvasShape(
      sliderValue,
      setWidth,
      setHeight,
      true,
      handleSetNoOfStickersPerPage
    );
    // chromeEffect && drawChromeEffect(true, false, isChrome);
    setHeightRatio(heightPrint, true);
    setWidthRatio(widthPrint, true);
  };

  const handleSelectCircleShape = () => {
    drawCircle();
    setIsRoundedCorners(false);
    // chromeEffect && drawChromeEffect(false, false, isChrome);
    setIsRectangle(false);
    resizeCanvasShape(
      sliderValue,
      setWidth,
      setHeight,
      false,
      handleSetNoOfStickersPerPage
    );
    if (heightPrint > widthPrint) {
      setHeightRatio(widthPrint, false);
      setWidthRatio(widthPrint, false);
    } else {
      setHeightRatio(heightPrint, false);
      setWidthRatio(heightPrint, false);
    }
  };

  const handleSelectRoundedRectangularShape = () => {
    setIsRoundedCorners(true);
    drawRoundedRectangle();
    setIsRectangle(true);
    // chromeEffect && drawChromeEffect(true, true, isChrome);
    resizeCanvasShape(
      sliderValue,
      setWidth,
      setHeight,
      true,
      handleSetNoOfStickersPerPage
    );
    setHeightRatio(heightPrint, true);
    setWidthRatio(widthPrint, true);
  };

  const setTransparentMaterial = (value) => {
    setIsTransparent(true);
    // setChromeEffect(false);
    setMaterial(value);
    setTransparentBackground(true);
    const noOnSheetOfPaper = computeNumberOfStickersOnSheetOfPaper(
      isDieCut,
      widthPrint,
      heightPrint,
      value
    );
    setQuantity(numberOfSheet * noOnSheetOfPaper);
    setWrapping("None");
    dispatch(changeNoOnSheetValue(noOnSheetOfPaper));
  };
  const setPremiumMaterial = (selectedMaterial) => {
    setIsTransparent(false);
    // setChromeEffect(false);
    setMaterial(selectedMaterial);
    setPremiumBackground(imageBackgroundColor);
    const noOnSheetOfPaper = computeNumberOfStickersOnSheetOfPaper(
      isDieCut,
      widthPrint,
      heightPrint,
      selectedMaterial
    );
    if (MATERIAL_TYPES_WITH_PVC.includes(selectedMaterial)) {
      setWrapping("None");
    }
    setQuantity(numberOfSheet * noOnSheetOfPaper);
    dispatch(changeNoOnSheetValue(noOnSheetOfPaper));
  };

  // const setGoldMaterial = () => {
  //   setIsTransparent(false);
  //   setIsChrome(false);
  //   setMaterial("Gold");
  //   setChromeEffect(true);
  //   handleSelectGoldColor(false);
  // };

  // const setChromeMaterial = () => {
  //   setIsTransparent(false);
  //   setIsChrome(true);
  //   setMaterial("Chrome");
  //   setChromeEffect(true);
  //   handleSelectGoldColor(true);
  // };

  const handleSetDieCut = (value) => {
    setIsDieCut(value);
    handleSetNoOfStickersPerPage(value, widthPrint, heightPrint, false);
  };

  return (
    <Grid
      container
      spacing={2}
      height="100%"
      padding="1%"
      overflow="hidden"
      sx={{
        backgroundImage: isTransparent && `url(${Background_transparent})`,
        minHeight: `calc(100vh - 110px)`,
      }}
      justifyContent="center"
    >
      <Helmet>
        <title>PrintWorks Configurator Stickere</title>
        <meta
          name="description"
          content="
          Bine ați venit pe pagina noastră web dedicată configuratorului de stickere online! Aici, vă oferim o experiență inovatoare și personalizată, permițându-vă să vă transformați ideile în realitate prin designul propriilor stickere. Cu o interfață intuitivă și prietenoasă, puteți alege forme, dimensiuni și culori și încărca imagini  pentru a crea exact produsul dorit. Beneficiați de preview live pentru a vedea cum evoluează designul în timp real și asigurați-vă că fiecare detaliu este perfect. Descoperiți creativitatea fără limite și comandați stickerele personalizate care vă definesc!"
        />
        <meta
          name="title"
          content="Printworks: Designul Tău, Stickerele Tale: Configureaza Online și Creează Stickere Personalizate Unice!"
        />
      </Helmet>
      <PricingList
        quantity={quantity}
        dimension={heightPrint * widthPrint}
        material={material}
        dieCut={isDieCut}
        anchorEl={anchorEl}
        wrapping={wrapping}
        setQuantity={setQuantity}
        handleClose={() => setAnchorEl(null)}
        isFolio={hasFolio}
      />

      <UploadModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleUpload={addImageHanlder}
      />
      <Grid item container xs={12} justifyContent="center">
        <Grid item container xs={12} zIndex={1}>
          <ImageActionButtons
            remove={() => handleResetCanvas()}
            flipVertical={() => flipObjectVertical()}
            flipHorizontal={() => flipObjectHorizontal()}
            centerVertical={() => alignObjectVertical()}
            centerHorizontal={() => alignObjectHorizontal()}
          />
        </Grid>
        <Grid container item xs={12} marginTop="-55px" justifyContent="center">
          <canvas
            id="canvas"
            height={0.6 * window.outerHeight}
            width={window.outerWidth}
          ></canvas>
        </Grid>
        <Grid item xs={12} display="none">
          <canvas id="canvas1" height="3000px" width="3000px"></canvas>
          <canvas id="canvas2" height="3000px" width="3000px"></canvas>
        </Grid>
        {matches && (
          <Button
            variant="contained"
            color="dark"
            startIcon={<AddShoppingCartIcon />}
            sx={{ position: "absolute", bottom: "12vh", right: "50px" }}
            onClick={handleDownload}
          >
            {t("add.to.cart")}
          </Button>
        )}
        {matches && !hasFolio && (
          <>
            <input
              accept=".svg"
              style={{ display: "none" }}
              id="folio-button"
              onChange={(event) => addFolioGraphicsHandler(event, isRectangle)}
              type="file"
              ref={folioInputRef}
            />
            <label htmlFor="folio-button">
              <Button
                variant="contained"
                color="dark"
                sx={{ position: "absolute", bottom: "30vh", right: "50px" }}
                onClick={() => folioInputRef.current.click()}
              >
                {t("add.folio")}
              </Button>
            </label>
          </>
        )}
        {matches && hasFolio && (
          <Button
            variant="contained"
            color="dark"
            sx={{ position: "absolute", bottom: "30vh", right: "50px" }}
            onClick={removeFolioGraphicsHandles}
          >
            {t("remove.folio")}
          </Button>
        )}
        {matches && (
          <Tooltip title={t("cutting.effect")}>
            <InfoIcon
              color="dark"
              sx={{
                position: "absolute",
                bottom: "23vh",
                right: "65px",
              }}
            />
          </Tooltip>
        )}
        {matches && (
          <Tooltip title={t("what.is.diecut")}>
            <FormControlLabel
              control={
                <Checkbox
                  color="dark"
                  sx={{ color: colors.customYellow }}
                  checked={isDieCut}
                  onChange={(event) => handleSetDieCut(event.target.checked)}
                />
              }
              label={
                <Typography sx={{ color: colors.customYellow }}>
                  {t("die.cut")}
                </Typography>
              }
              sx={{
                position: "absolute",
                bottom: "17vh",
                right: "50px",
              }}
            />
          </Tooltip>
        )}
      </Grid>
      {showColors && (
        <ClickAwayListener onClickAway={() => setShowColors(false)}>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              bottom: { xs: "30vh", md: "10vh" },
              position: "fixed",
              background: colors.secondary,
              width: "100vw",
              right: 0,
            }}
          >
            <ChromePicker
              className={classes.colorPicker}
              color={imageBackgroundColor}
              pointer={<AdbIcon />}
              disableAlpha
              defaultView="rgb"
              onChange={handleChangeColor}
            />
          </Grid>
        </ClickAwayListener>
      )}

      <Grid
        container
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          bottom: 0,
          position: "fixed",
          background: "white",
          width: "100vw",
          right: 0,
          left: 0,
          minHeight: "10vh",
          padding: "10px",
          borderTop: `1px solid ${colors.text}`,
        }}
      >
        {!matches && (
          <Grid item xs={12}>
            <Button onClick={() => setMenuIn(!menuIn)}>
              {menuIn ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              <Typography>Menu</Typography>
            </Button>
          </Grid>
        )}
        <Fade in={menuIn || matches}>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            display={menuIn || matches ? "flex" : "none"}
          >
            <Grid item xs={4} md={1}>
              <TextField
                value={widthPrint}
                onChange={(event) =>
                  setWidthRatio(event.target.value, isRectangle)
                }
                onBlur={(event) => {
                  if (parseInt(event.target.value) > maxWidthPrint) {
                    setWidthRatio(maxWidthPrint, isRectangle);
                  } else if (parseInt(event.target.value) < 3) {
                    setWidthRatio(3, isRectangle);
                  } else setWidthRatio(event.target.value, isRectangle);
                  // if (parseInt(event.target.value) / noOnSheet < 10) {
                  //   setQuantity(
                  //     noOnSheet *
                  //       Math.ceil(parseInt(event.target.value) / noOnSheet)
                  //   );
                  // } else setQuantity(event.target.value);
                }}
                placeholder={t("width")}
                label={t("width")}
                className={classes.input}
                type="number"
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 10,
                  },
                  endAdornment: (
                    <InputAdornment position="start">cm</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4} md={1}>
              <TextField
                value={heightPrint}
                onChange={(event) =>
                  setHeightRatio(event.target.value, isRectangle)
                }
                onBlur={(event) => {
                  if (parseInt(event.target.value) > maxHeightPrint) {
                    setHeightRatio(maxHeightPrint, isRectangle);
                  } else if (parseInt(event.target.value) < 3) {
                    setHeightRatio(3, isRectangle);
                  } else setHeightRatio(event.target.value, isRectangle);
                  // if (parseInt(quantity) / noOnSheet < 10) {
                  //   setQuantity(
                  //     noOnSheet *
                  //       Math.ceil(parseInt(event.target.value) / noOnSheet)
                  //   );
                  // } else setQuantity(event.target.value);
                }}
                label={t("height")}
                placeholder={t("height")}
                className={classes.input}
                type="number"
                inputProps={{ min: 0, max: 999, step: "1" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">cm</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={4} md={1}>
              <Tooltip
                title={`Stickerele se listeaza in coala. Pentru dimensiunea selectata pe o coala incap ${noOnSheet} autocolante. Pentru o cantitate mai mica de 10 coli, autocolantele se vor comercializa ca multiplu de ${noOnSheet} `}
              >
                <TextField
                  value={quantity}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  onChange={(event) => setQuantity(event.target.value)}
                  onBlur={(event) => {
                    if (parseInt(event.target.value) / noOnSheet < 10) {
                      setQuantity(
                        noOnSheet *
                          Math.ceil(parseInt(event.target.value) / noOnSheet)
                      );
                    } else setQuantity(event.target.value);
                  }}
                  label={t("quantity")}
                  placeholder={t("quantity")}
                  type="number"
                  className={classes.input}
                />
              </Tooltip>
            </Grid>
            {matches && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderRightWidth: 1, background: colors.text }}
              />
            )}
            <Grid item xs={12} md={4}>
              <Typography id="slider" gutterBottom>
                {t("border")}
              </Typography>
              <Slider
                defaultValue={320}
                value={sliderValue}
                step={2}
                aria-label="Default"
                min={0}
                max={matches ? 500 : 350}
                color="dark"
                onChange={(event, newValue) => {
                  setSliderValue(event.target.value);
                  resizeShape(event.target.value);
                }}
                valueLabelDisplay="auto"
              />
            </Grid>
            {matches && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderRightWidth: 1, background: colors.text }}
              />
            )}
            <Grid item xs={6} md={1}>
              <ShapeMenu
                handleSelectRectangularShape={handleSelectRectangularShape}
                handleSelectCircleShape={handleSelectCircleShape}
                handleSelectRoundedRectangularShape={
                  handleSelectRoundedRectangularShape
                }
                shape={shape}
                setShape={setShape}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={1}
              visibility={isTransparent ? "hidden" : "visible"}
            >
              <Button
                startIcon={
                  <SquareIcon
                    sx={{ color: imageBackgroundColor, background: "black" }}
                  />
                }
                sx={{ height: "60px" }}
                onClick={() => setShowColors(true)}
              >
                {t("pick.color")}
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              md={1}
              sx={{ marginTop: { xs: "10px", md: "0" } }}
            >
              <MaterialMenu
                setTransparentMaterial={setTransparentMaterial}
                setPremiumMaterial={setPremiumMaterial}
                material={material}
              />
            </Grid>

            <Grid
              item
              xs={6}
              md={1}
              sx={{ marginTop: { xs: "10px", md: "0" } }}
            >
              <WrappingMenu
                setWrapping={setWrapping}
                width={widthPrint}
                height={heightPrint}
                wrapping={wrapping}
                hasFolio={hasFolio}
                material={material}
              />
            </Grid>
            {!matches && (
              <Grid
                item
                container
                xs={12}
                marginTop="10px"
                justifyContent="space-evenly"
              >
                <Grid item container xs={4} justifyContent="center">
                  <Button
                    variant="contained"
                    color="dark"
                    startIcon={<AddShoppingCartIcon />}
                    onClick={handleDownload}
                  >
                    {t("add.to.cart")}
                  </Button>
                </Grid>
                <Grid item container xs={4} justifyContent="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="dark"
                        sx={{ color: colors.customYellow }}
                        checked={isDieCut}
                        onChange={(event) =>
                          handleSetDieCut(event.target.checked)
                        }
                      />
                    }
                    label={
                      <Typography sx={{ color: colors.customYellow }}>
                        {t("die.cut")}
                      </Typography>
                    }
                  />
                </Grid>
                {!hasFolio && (
                  <Grid item container xs={4} justifyContent="center">
                    <input
                      accept=".svg"
                      style={{ display: "none" }}
                      id="folio-button"
                      onChange={(event) =>
                        addFolioGraphicsHandler(event, isRectangle)
                      }
                      type="file"
                      ref={folioInputRef}
                    />
                    <label htmlFor="folio-button">
                      <Button
                        variant="contained"
                        color="dark"
                        onClick={() => folioInputRef.current.click()}
                      >
                        {t("add.folio")}
                      </Button>
                    </label>
                  </Grid>
                )}
                {hasFolio && (
                  <Grid item container xs={4} justifyContent="center">
                    <Button
                      variant="contained"
                      color="dark"
                      onClick={removeFolioGraphicsHandles}
                    >
                      {t("remove.folio")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Fade>
      </Grid>
    </Grid>
  );
};
